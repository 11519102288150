import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ArticleHeader,
  BlocksRender,
  PageRichText,
  SEO,
} from '@wunderdogsw/finbb-dms-theme'

import Layout from '../components/layout/layout'
import * as blockComponents from '../blocks'
import useApplicationPrivacyPolicy from '../hooks/use-application-privacy-policy'
import { PageContextProps } from './page'

type DataProps = {
  contentfulArticlePage: {
    blocksAfter: {
      __typename: string
      [key: string]: any
    }[]
    blocksBefore: {
      __typename: string
      [key: string]: any
    }[]
    body: {
      raw: string
      references: any[]
    }
    fields: {
      route: string
    }
    fullTitle: string
    metaDescription?: { metaDescription: string }
    metaImage?: {
      file: {
        url: string
      }
      gatsbyImageData: IGatsbyImageData
    }
    metaTitle: string
    parentPage: {
      fields: {
        route: string
      }
      menuTitle: string
    }

    publishedAt: string
  }
}

const ArticlePage: React.FC<PageProps<DataProps, PageContextProps>> = ({
  data,
  pageContext,
}) => {
  const applicationData = useApplicationPrivacyPolicy()

  const { contentfulArticlePage: page } = data
  const {
    metaTitle,
    metaDescription,
    metaImage,
    fullTitle,
    publishedAt,
    parentPage,
    blocksBefore,
    blocksAfter,
    body,
  } = page
  const { route } = page.fields

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription && metaDescription.metaDescription}
        image={metaImage && metaImage.file.url}
        path={route}
      />
      <article>
        <ArticleHeader
          title={fullTitle}
          publishedAt={publishedAt}
          gatsbyImageData={metaImage?.gatsbyImageData}
          path={route}
          parent={parentPage}
        />
        {blocksBefore && (
          <BlocksRender
            applicationData={applicationData}
            components={blockComponents}
            locale={pageContext.locale}
          >
            {blocksBefore}
          </BlocksRender>
        )}
        <div className="relative z-10 container mx-auto px-4 sm:px-8 py-8">
          <PageRichText>{body}</PageRichText>
        </div>
        {blocksAfter && (
          <BlocksRender
            applicationData={applicationData}
            components={blockComponents}
            locale={pageContext.locale}
          >
            {blocksAfter}
          </BlocksRender>
        )}
      </article>
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulArticlePage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      blocksAfter {
        __typename
        ...Blocks
      }
      blocksBefore {
        __typename
        ...Blocks
      }
      body {
        raw
        references {
          __typename
          ... on Node {
            ...RichTextReferences
          }
        }
      }
      fields {
        route
      }
      fullTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        file {
          url
        }
        gatsbyImageData(width: 2560)
      }
      metaTitle
      parentPage {
        fields {
          route
        }
        menuTitle
      }
      publishedAt(formatString: "MMM D, YYYY")
    }
  }
`
